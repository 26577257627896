import { Vue } from "vue-class-component";
import { RouterLink, RouterView } from "vue-router";
import './index.scss'

export default class Activity extends Vue {
  render(): JSX.Element {
    return <div class="activity">
      {this.$route.path.split('/').length <= 3 && <div class="activity-link">
        {/* <RouterLink to="/activity/activity" activeClass="active">活动</RouterLink>
        <span></span>
        <RouterLink to="/activity/show" activeClass="active">展览</RouterLink> */}
      </div>
      }
      <RouterView />
    </div>
  }
}
